<template>
  <BaseDialog
    :show="true"
    :btn1Loading="loading"
    title="票券模組參數設定"
    width="670px"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>票券顯示名稱</p>
        <el-tooltip placement="right">
          <div slot="content">
            您可以自行設定票券在客人端顯示的文字，至多 5 個字。<br>
            （票券種類有：使用券、兌換券
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <BaseElFormItem prop="displayName">
        <BaseElInput
          v-model="formData.displayName"
          clearable
          :maxlength="5"
          show-word-limit
        />
      </BaseElFormItem>
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>到期通知發送天數設定</p>
        <el-tooltip placement="right">
          <div slot="content">
            系統將於您在到期日前 X 天的中<br>
            午 12 點推播 Line 訊息提醒 舉<br>
            例：若設定到期日前 3 天通知假設<br>
            位客人的堂票是12/31 到期，系<br>
            統將於 12/28 的中午 12 點提醒
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <BaseElFormItem prop="expireNotificationBefore">
        <BaseElInput v-model="formData.expireNotificationBefore" clearable />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useShop } from '@/use/shop'
import { UpdateCouponConfig } from '@/api/coupon'
import notifyMessage from '@/config/notifyMessage'
import { get, isUndefined } from 'lodash'

export default defineComponent({
  name: 'CouponSettingsEditModal',
  components: {
    BaseDialog,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const {
      initFormData,
      formData,
      formRef,
      loading,
      checkForm,
    } = useBaseForm()
    const { shopId } = useShop()

    initFormData({
      displayName: '',
      expireNotificationBefore: 7,
    })

    const formRules = computed(() => {
      const rules = {
        displayName: [noEmptyRules()],
        expireNotificationBefore: [noEmptyRules()],
      }
      return rules
    })

    const updateCouponConfig = async () => {
      loading.value = true
      const [, err] = await UpdateCouponConfig({
        shopId: shopId.value,
        displayName: formData.displayName,
        expireNotificationBefore: formData.expireNotificationBefore,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true

      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      await updateCouponConfig()
    }

    onMounted(() => {
      simpleSyncFormData(props.configData, formData)

      if (!(get(props.configData, 'expireNotificationBefore'))) {
        formData.expireNotificationBefore = 7
      }
      if (isUndefined(get(props.configData, 'displayName'))) {
        formData.displayName = '票券'
      }
    })

    return { formData, formRules, formRef, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
